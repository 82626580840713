import {
  Box,
  Img,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

interface Props {
  description: React.ReactNode;
  largeImageSrc: string;
  smallImageSrc: string;
  title: string;
  url: string;
}

export const Work: React.FC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        _hover={{ bgColor: 'gray.400' }}
        as="button"
        bgColor="gray.300"
        borderRadius="md"
        onClick={onOpen}
        overflow="hidden"
        position="relative"
        sx={{ aspectRatio: '16 / 9' }}
        transitionDuration="normal"
        transitionProperty="colors"
        userSelect="none"
      >
        <Img
          alt={props.title}
          borderRadius="sm"
          boxShadow="lg"
          h="calc(100% - 48px)"
          left="50%"
          position="absolute"
          src={props.smallImageSrc}
          top="50%"
          transform="translate(-50%, -50%)"
          w="auto"
        />
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="outside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              bgColor="gray.300"
              borderRadius="md"
              mb={6}
              overflow="hidden"
              p={8}
            >
              <Img
                alt={props.title}
                borderRadius="base"
                boxShadow="lg"
                display="block"
                loading="lazy"
                maxH={64}
                maxW="100%"
                mx="auto"
                src={props.largeImageSrc}
              />
            </Box>
            {props.description}
          </ModalBody>
          <ModalFooter justifyContent="flex-start" pb={6}>
            <Link href={props.url} isExternal>
              {props.url}
            </Link>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
