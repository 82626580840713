import { Box, Container } from '@chakra-ui/react';
import React from 'react';
import { NAME } from '../config';

export const Footer: React.FC = () => {
  return (
    <Container>
      <Box
        borderTop="1px"
        borderTopColor="blackAlpha.200"
        color="gray.500"
        paddingY={16}
        textAlign="center"
      >
        &copy; {NAME}
      </Box>
    </Container>
  );
};
