import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Heading,
  HStack,
  Img,
  Link,
  SimpleGrid,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { FaGithub, FaTwitter } from 'react-icons/fa';
import { MdOutlineArticle } from 'react-icons/md';
import { NAME } from '../config';
import { Footer } from './Footer';
import { Header } from './Header';
import { Timeline } from './Timeline';
import { Work } from './Work';

const logoImage = new URL('../images/logo.svg', import.meta.url);

const boardGameTimerImageLarge = new URL(
  '../images/board-game-timer.png',
  import.meta.url,
);
const boardGameTimerImageSmall = new URL(
  '../images/board-game-timer.png?height=512',
  import.meta.url,
);

export const Page: React.FC = () => {
  return (
    <>
      <Header />
      <Container paddingTop={16}>
        <Box marginBottom={16}>
          <HStack marginBottom={8} spacing={6}>
            <Tooltip
              bgColor="transparent"
              boxShadow="none"
              closeOnClick={false}
              color="gray.900"
              fontWeight="bold"
              label="＼もふふ／"
              placement="top"
            >
              <Img
                alt="icon"
                boxSize={['96px', '96px', '128px']}
                src={logoImage.pathname}
              />
            </Tooltip>
            <Box>
              <Heading marginBottom={2} size="2xl">
                {NAME}
              </Heading>
              <Heading color="gray.500" size="xs">
                ソフトウェアエンジニア
              </Heading>
            </Box>
          </HStack>
          <Text marginBottom={4}>
            ウェブアプリケーションのフロントエンド・バックエンド開発に従事している。
            アニメとボードゲームが好き。
          </Text>
          <ButtonGroup variant="outline">
            <Button
              as="a"
              href="https://github.com/reminjp"
              leftIcon={<FaGithub />}
              rel="noopener"
              target="_blank"
            >
              GitHub
            </Button>
            <Button
              as="a"
              href="https://twitter.com/reminjp"
              leftIcon={<FaTwitter />}
              rel="noopener"
              target="_blank"
            >
              Twitter
            </Button>
            <Button
              as="a"
              href="https://blog.remin.jp"
              leftIcon={<MdOutlineArticle />}
              rel="noopener"
              target="_blank"
            >
              ブログ
            </Button>
          </ButtonGroup>
        </Box>
        <Box marginBottom={16}>
          <Heading marginBottom={6}>趣味の制作物</Heading>
          <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={4}>
            <Work
              description="緩いプレイ時間管理のためのオンラインボードゲームタイマー。URLやQRコードで部屋を共有し、複数人で操作できる。"
              largeImageSrc={boardGameTimerImageLarge.pathname}
              smallImageSrc={boardGameTimerImageSmall.pathname}
              title="ゆるボードゲームタイマー"
              url="https://board-game-timer.remin.app"
            />
          </SimpleGrid>
        </Box>
        <Box marginBottom={16}>
          <Heading marginBottom={6}>資格・検定</Heading>
          <Timeline
            data={[
              { year: 2024, items: ['色彩検定 1級'] },
              { year: 2022, items: ['色彩検定 UC級'] },
              { year: 2016, items: ['基本情報技術者試験'] },
            ]}
          />
        </Box>
        <Box marginBottom={16}>
          <Heading marginBottom={6}>競技プログラミング</Heading>
          <Timeline
            data={[
              {
                year: 2020,
                items: [
                  <>
                    <Link href="https://atcoder.jp/users/remin" isExternal>
                      AtCoder
                    </Link>{' '}
                    黄 到達
                  </>,
                ],
              },
              {
                year: 2019,
                items: ['第一回日本最強プログラマー学生選手権決勝 167位'],
              },
              { year: 2018, items: ['ACM-ICPC 2018 Asia Yokohama Regional'] },
              { year: 2017, items: ['ACM-ICPC 2017 Asia Tsukuba Regional'] },
            ]}
          />
        </Box>
      </Container>
      <Footer />
    </>
  );
};
