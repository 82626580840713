import { Box, Fade, Heading, HStack, Img, Portal } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useWindowScroll } from 'react-use';
import { NAME } from '../config';

const logo = new URL('../images/logo.svg', import.meta.url);

const __Header: React.FC = () => {
  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <HStack
      bgColor="gray.50"
      boxShadow="base"
      paddingX={[4, 4, 8]}
      paddingY={4}
      spacing={2}
      userSelect="none"
    >
      <Img
        alt="icon"
        boxSize="48px"
        onClick={scrollToTop}
        role="button"
        src={logo.pathname}
      />
      <Heading onClick={scrollToTop} role="button" size="md">
        {NAME}
      </Heading>
    </HStack>
  );
};

const _Header = React.memo(__Header);

export const Header: React.FC = () => {
  const { y } = useWindowScroll();
  const isPageTop = y < 128;

  return (
    <Portal>
      <Box
        left={0}
        position="fixed"
        style={{ pointerEvents: isPageTop ? 'none' : undefined }}
        top={0}
        w="100%"
      >
        <Fade in={!isPageTop}>
          <_Header />
        </Fade>
      </Box>
    </Portal>
  );
};
