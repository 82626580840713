import { ChakraProvider } from '@chakra-ui/provider';
import { extendTheme, theme as defaultTheme } from '@chakra-ui/react';
import React from 'react';
import { Page } from './components/Page';

const theme = extendTheme({
  colors: {
    brand: defaultTheme.colors.blue,
    gray: {
      // "Zinc" from Tailwind CSS
      50: '#fafafa',
      100: '#f4f4f5',
      200: '#e4e4e7',
      300: '#d4d4d8',
      400: '#a1a1aa',
      500: '#71717a',
      600: '#52525b',
      700: '#3f3f46',
      800: '#27272a',
      900: '#18181b',
    },
  },
  fonts: {
    body: 'Barlow, "M PLUS 1p", sans-serif',
    heading: 'Barlow, "M PLUS 1p", sans-serif',
    mono: '"JetBrains Mono", "M PLUS 1p", monospace',
  },
  components: {
    Button: {
      defaultProps: {
        size: 'sm',
      },
    },
    Container: {
      baseStyle: {
        maxW: 'container.lg',
      },
    },
    Heading: {
      defaultProps: {
        size: 'md',
      },
    },
    Link: {
      baseStyle: {
        color: 'brand.600',
      },
      defaultProps: {
        undefined: '',
      },
    },
  },
  styles: {
    global: {
      body: {
        bgColor: 'gray.50',
        lineHeight: 1.75,
      },
    },
  },
});

export const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Page />
    </ChakraProvider>
  );
};
