import { HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';

interface Props {
  data: {
    items: React.ReactNode[];
    year: number;
  }[];
}

const TimelineItem: React.FC<Props['data'][number]> = ({ items, year }) => {
  return (
    <HStack alignItems="flex-start" spacing={4}>
      <Text fontFamily="mono" fontWeight="bold">
        {year}
      </Text>
      <VStack alignItems="flex-start" spacing={0}>
        {items.map((v, i) => (
          <Text key={i}>{v}</Text>
        ))}
      </VStack>
    </HStack>
  );
};

export const Timeline: React.FC<Props> = ({ data }) => {
  return (
    <VStack alignItems="flex-start" spacing={4}>
      {data.map((v, i) => (
        <TimelineItem key={i} {...v} />
      ))}
    </VStack>
  );
};
